import loadable from "@loadable/component"
import { graphql, PageProps } from "gatsby"
import React from "react"
import { OverviewPageQuery } from "../__generated__/graphql-gatsby"

const ActiviteitComponent = loadable(
  () => import("../components/page/activiteit")
)
const BaseComponent = loadable(() => import("../components/page/base"))
const NieuwsComponent = loadable(() => import("../components/page/nieuws"))
const PageComponent = loadable(() => import("../components/page/page"))

type IProps = PageProps<OverviewPageQuery>

const OverviewPage: React.FC<IProps> = ({ data }) => {
  const key = String(data.wpContentNode?.uri)

  switch (data.wpContentNode?.__typename) {
    case "WpActiviteit":
      return (
        <ActiviteitComponent
          data={data.wpContentNode}
          overviewData={data.allWpContentNode.nodes}
          sessionKey={key}
        />
      )
    case "WpPage":
      return (
        <PageComponent
          data={data.wpContentNode}
          overviewData={data.allWpContentNode.nodes}
          sessionKey={key}
        />
      )
    case "WpErvaring":
    case "WpVacature":
      return (
        <BaseComponent
          data={data.wpContentNode}
          overviewData={data.allWpContentNode.nodes}
          sessionKey={key}
        />
      )
    case "WpNieuws":
      return (
        <NieuwsComponent
          data={data.wpContentNode}
          overviewData={data.allWpContentNode.nodes}
          sessionKey={key}
        />
      )
    default:
      return null
  }
}

export default OverviewPage

export const query = graphql`
  fragment Vrijwilliger on WpVrijwilliger {
    __typename
    id
    tags {
      nodes {
        id
      }
    }
    vrijwilliger {
      omschrijving
      vrijwilliger {
        info {
          weergaveNaam
          omschrijving
          eMail
          telefoon
          url {
            url
            title
            target
          }
        }
        afbeelding {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(transformOptions: { fit: CONTAIN })
            }
          }
        }
      }
    }
  }
  fragment OverviewData on WpContentNode {
    __typename
    id
    uri
    ... on WpVrijwilliger {
      ...Vrijwilliger
    }
    ... on WpNodeWithTitle {
      title
    }
    ... on WpNodeWithExcerpt {
      excerpt
    }
    ... on WpNodeWithFeaturedImage {
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
      }
    }
    ... on WpActiviteit {
      tags {
        nodes {
          id
        }
      }
    }
    ... on WpErvaring {
      tags {
        nodes {
          id
        }
      }
    }
  }
  query OverviewPage($id: String!, $postType: String!) {
    wpContentNode(id: { eq: $id }) {
      __typename
      id
      uri
      ... on WpPage {
        ...wpPage
      }
      ... on WpErvaring {
        ...wpErvaring
      }
      ... on WpActiviteit {
        ...wpActiviteit
      }
      ... on WpNieuws {
        ...wpNieuws
      }
      ... on WpVacature {
        ...wpVacature
      }
    }
    allWpContentNode(
      filter: { nodeType: { eq: $postType } }
      sort: { fields: dateGmt, order: DESC }
    ) {
      nodes {
        ...OverviewData
      }
    }
  }
`
